<template>
<input type="text" v-model="displayValue" @blur="blurInput" @change="changeInput" @focus="isInputActive = true" class="form-control" autocomplete="off" />
</template>

<script>
export default {
    props: {
        value: null,
        min: null,
        blur: {
            type: Function
        },
        change: {
            type: Function
        }
    },
    data: function () {
        return {
            isInputActive: false
        }
    },
    methods: {
        blurInput() {
            this.isInputActive = false;
            if (this.blur) this.blur();
        },
        changeInput() {
            if (this.change) this.change();
        }
    },
    computed: {
        displayValue: {
            get: function () {
                return this.formatNumber(this.value, !this.isInputActive);
            },
            set: function (modifiedValue) {
                // Recalculate value after ignoring "$" and "," in user input
                let newValue = parseFloat(modifiedValue.replace(/[^\d.]/g, ""))
                // Ensure that it is not NaN
                if (isNaN(newValue) || (this.min && newValue < this.min)) {
                    newValue = this.min ? this.min : 0;
                }
                // Note: we cannot set this.value as it is a "prop". It needs to be passed to parent component
                // $emit the event so that parent component gets it
                this.$emit('input', newValue)
            }
        }
    }
};
</script>
